import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'
import { Link } from 'gatsby'

const About = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      window.jQuery('.photo-gallery').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
      })

      window.jQuery(function () {
        window.jQuery('.open-photo-gallery').click(function () {
          window.jQuery('body').addClass('active')
        })
        window.jQuery('.photo-gallery-modal-close').click(function () {
          window.jQuery('body').removeClass('active')
        })
      })
    }, 1000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      window.$(document).ready(function () {
        var a = 0
        window.jQuery(window).scroll(function () {
          var oTop =
            window.jQuery('#counter').offset()?.top - window.innerHeight
          if (a == 0 && window.jQuery(window).scrollTop() > oTop) {
            window.jQuery('.counter-value').each(function () {
              var $this = window.jQuery(this),
                countTo = $this.attr('data-count')
              console.log('🚀 ~ countTo', countTo)
              window
                .jQuery({
                  countNum: $this.text(),
                })
                .animate(
                  {
                    countNum: countTo,
                  },

                  {
                    duration: 1000,
                    easing: 'swing',
                    step: function () {
                      //$this.text(Math.ceil(this.countNum));
                      $this.text(Math.ceil(this.countNum).toLocaleString('en'))
                    },
                    complete: function () {
                      $this.text(Math.ceil(this.countNum).toLocaleString('en'))
                      //alert('finished');
                    },
                  }
                )
            })
            a = 1
          }
        })
      })
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'About | immence'}
        description={
          'We turn your ideas into design and designs into practical digital products, such as websites or mobile apps.'
        }
      />
      <Layout>
        <div>
          <section className="content-page bg-service" id="service_page">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content_padd">
                    <h2 className="hero-heading mt-2">
                      We craft
                      <br />
                      <span className="blue-span">Digital experiences</span>
                      <br /> that <span className="blue-span">spark joy.</span>
                    </h2>
                    <p className="section-main-para heading-setting mb-0">
                      We turn your ideas into design and designs into practical
                      digital products, such as websites or mobile apps.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 center-item">
                  <div className="service_img">
                    <img
                      src={require('../img/heroImg/about.png').default}
                      alt
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="com-section about-company">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul className="counter-box" id="counter">
                    <li>
                      <h3 className="sub-title">
                        Overview of our <br />
                        <span className="pink-span"> progress </span> and{' '}
                        <span className="pink-span">tasks</span>
                      </h3>
                    </li>
                    <li>
                      <h3 className="counter-number">
                        <span
                          className="counter counter-value"
                          data-count={250}
                        >
                          1
                        </span>
                        +
                      </h3>
                      <p>
                        <b>Projects</b> <br />
                        we completed
                      </p>
                    </li>
                    <li>
                      <h3 className="counter-number">
                        <span
                          className="counter counter-value"
                          data-count={150}
                        >
                          1
                        </span>
                        +
                      </h3>
                      <p>
                        <b>Clients</b> <br />
                        we worked with
                      </p>
                    </li>
                    <li>
                      <h3 className="counter-number">
                        <span className="counter counter-value" data-count={20}>
                          1
                        </span>
                        +
                      </h3>
                      <p>
                        <b>Countries</b> <br />
                        we served
                      </p>
                    </li>
                    <li>
                      <h3 className="counter-number">
                        <span className="counter counter-value" data-count={13}>
                          1
                        </span>
                        +
                      </h3>
                      <p>
                        <b>Industries</b> <br />
                        we work for
                      </p>
                    </li>
                    <li>
                      <h3 className="counter-number">
                        <span className="counter counter-value" data-count={25}>
                          1
                        </span>
                        +
                      </h3>
                      <p>
                        <b>Team</b> <br />
                        we built
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div
                    className="we-are-content aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="overlay-img" />
                    <div className="company-img">
                      <img
                        src={require('../img/about_company_img.svg').default}
                        alt="immence - About company"
                      />
                    </div>
                  </div>
                </div>
                <div className="softwareLink">
                  <div className="softwareIcon">
                    <a
                      href="https://clutch.co/in/developers/application-modernization"
                      target="_blank"
                    >
                      <img
                        src={require('../img/clutch_badge.png').default}
                        alt="`clutch`_badge"
                        className="img-fluid"
                      />
                    </a>
                    <a
                      href="https://www.onlinedegree.com/article/best-software-development-education"
                      target="_blank"
                    >
                      <img
                        src={require('../img/onlinedegree_badge.png').default}
                        alt="onlinedegree_badge"
                        className="img-fluid"
                      />
                    </a>
                    <a
                      href="https://www.designrush.com/agency/software-development/real-estate"
                      target="_blank"
                    >
                      <img
                        src={require('../img/designrush_badge.png').default}
                        alt="designrush_badge"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="com-section about-core com-section bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-5 text-center aos-init aos-animate"
                    data-aos="fade-in"
                  >
                    <h2 className="hero-heading" data-aos="fade-up">
                      Who we are
                    </h2>

                    <p
                      className="section-main-para heading-setting m-auto aos-init aos-animate"
                      data-aos="fade-up"
                    >
                      We are a Global Pioneer in digital transformation that
                      delivers leading-edge solutions to emerging technology
                      startups, SMEs and large corporations. We have been
                      helping companies and established brands reinventing their
                      business through digitalisation.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row maxwidthbox">
                <div className="col-md-6">
                  <div
                    className="our-mission common-vision bg-gray aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="mission-img">
                      <img
                        src={require('../img/red_flag_1.png').default}
                        alt="immence - Flag icon"
                      />
                    </div>
                    <h3>Our mission</h3>
                    <p>
                      Help people to fulfil their dreams by enhancing their
                      business idea into real world.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="our-mission common-vision bg-gray aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="mission-img">
                      <img
                        src={require('../img/eye_1.png').default}
                        alt="immence - Eye icon"
                      />
                    </div>
                    <h3>Our Vision</h3>
                    <p>Make every dreamer a successful entrepreneur.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="com-section offer-section">
            <div className="container">
              <div className="row position-relative">
                <div className="col-lg-5 offer-section-heading">
                  <h2 className="hero-heading">
                    Facts
                    <br /> Benefits
                    <br /> Aspirations <span className="blue-span" />
                  </h2>
                  <p>
                    We want what’s best for our team, which is why we offer
                    benefits that enable a healthy work-life balance.
                  </p>
                </div>
                <div className="col-lg-7">
                  <div className="offer-main-box position-relative">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="left-offer-box position-relative">
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/agreement.svg')
                                    .default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>No Bond System</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/frinderly_enviroment.svg')
                                    .default
                                }
                                alt="frinderly_enviroment"
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Friendly environment</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/leaves.svg').default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>18 Paid leaves and 10 Holidays</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/work_balance.svg')
                                    .default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Work life balance</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/flexbile_timing.svg')
                                    .default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>
                                Flexible time off (PTO) plus vacation time{' '}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="right-offer-box position-relative">
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/salary.svg').default
                                }
                                alt="5_day_working"
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Salary on first</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/5_days.svg').default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>5 days working </h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/celebrations.svg')
                                    .default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Celebrations </h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/growth.svg').default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Technical Growth</h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/games.svg').default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Games Indoor &amp; Outdoor </h3>
                            </div>
                          </div>
                          <div
                            className="offer-card offer-card-margin"
                            data-aos="fade-up"
                          >
                            <div className="offer-card-icon">
                              <img
                                src={
                                  require('../img/benefits/risks.svg').default
                                }
                                alt
                              />
                            </div>
                            <div className="offer-card-content">
                              <h3>Calculated risks and big swings</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="com-section tour-office bg-gray">
            <div className="container">
              <div className="link-photo">
                <h2 className="hero-heading text-center">
                  <span className="yellow-span">Glimpse</span> of{' '}
                  <span style={{ color: '#0231c8' }}>immencers</span> life!
                </h2>
                <div className="open-photo-gallery">
                  <div className="photo-box">
                    <div className="lg-photo" data-aos="fade-up">
                      <img
                        src={
                          require('../img/office_img/new_workspace.jpg').default
                        }
                        alt
                      />
                    </div>
                    <div className="sm-photo" data-aos="fade-up">
                      <div className="link-photo">
                        <p className="open-photo-gallery">
                          Click for more photo
                        </p>
                      </div>
                      <div className="sm-photo-img">
                        <img
                          src={
                            require('../img/office_img/office_3.jpg').default
                          }
                          alt
                        />
                      </div>
                    </div>
                  </div>
                  <div className="photo-box">
                    <div className="sm-photo" data-aos="fade-up">
                      <div className="sm-photo-img">
                        <img
                          src={require('../img/office_img/group.png').default}
                          alt
                        />
                      </div>
                    </div>
                    <div className="lg-photo" data-aos="fade-up">
                      <img
                        src={
                          require('../img/office_img/operation_floor.jpg')
                            .default
                        }
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="photo-gallery-section">
            <div className="photo-gallery-inner">
              <div className="photo-gallery-modal-close">Close</div>
              <div className="slick-slider photo-gallery">
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/new_workspace.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">Workspace</p>
                </div>
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/cafeteria.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">Cafeteria</p>
                </div>
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/corridor.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">Corridor</p>
                </div>
                <div className="slick-item">
                  <img
                    src={
                      require('../img/office_img/discussion_room.jpg').default
                    }
                    alt="immence_team"
                  />
                  <p className="slick_caption">Discussion Room</p>
                </div>
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/library.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">Library</p>
                </div>
                <div className="slick-item">
                  <img
                    src={
                      require('../img/office_img/navigation_room.jpg').default
                    }
                    alt="immence_team"
                  />
                  <p className="slick_caption">Navigation Room</p>
                </div>
                <div className="slick-item">
                  <img
                    src={
                      require('../img/office_img/operation_floor.jpg').default
                    }
                    alt="immence_team"
                  />
                  <p className="slick_caption">Operation Floor</p>
                </div>
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/celebration.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">Celebration</p>
                </div>
                <div className="slick-item">
                  <img
                    src={require('../img/office_img/team.jpg').default}
                    alt="immence_team"
                  />
                  <p className="slick_caption">immencers</p>
                </div>
              </div>
            </div>
          </div>

          <section className="com-section footer-core">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="text-center aos-init aos-animate"
                    data-aos="fade-in"
                  >
                    <h2 className="hero-heading" data-aos="fade-up">
                      Feel like becoming <br />
                      an <span className="blue-span">immencers</span>
                    </h2>
                    <Link
                      to="/careers"
                      className="btn btn-outline-dark-primary btn-ouline mt-5 text-center"
                    >
                      Open Positions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default About
